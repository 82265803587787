<template>
  <div id="map"></div>
</template>

<script>
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import MapboxLanguage from '@mapbox/mapbox-gl-language'
import useMapBoxObjects from "@composables/useMapBoxObjects";
import useMapDataHandler from '@/composables/useMapDataHandler'
import { ref, reactive, watch, computed, toRefs, getCurrentInstance } from "vue"
import { MAP_PLATFORM_MAPBOX } from '@config/constant'

const { initChannelData, getStyleByPlatform } = useMapDataHandler()
//import _ from 'lodash'
export default {
  name: "MapBox",
  props: {
    channel: {
      type: Object, default () {
        return initChannelData(MAP_PLATFORM_MAPBOX)
      }
    },
    channelInfoList: {
      type: Array, default () {
        return []
      }
    }
  },
  setup (props) {
    const emitter = getCurrentInstance().appContext.config.globalProperties.$emitter
    console.log("setup emitter", emitter)
    const { channel, channelInfoList } = toRefs(props)

    const map = ref(null)
    const markers = reactive([])
    const selectedMarker = reactive({ marker: null })
    const { addMarkers, activeMarker } = useMapBoxObjects(map, channel, channelInfoList, selectedMarker, emitter, markers)

    const channel4Show = computed(() => {
      if (channel.value == null) {
        return initChannelData(MAP_PLATFORM_MAPBOX)
      } else {
        return channel.value
      }
    })

    const mapStyle = computed(() => {
      console.log('channel4Show', channel4Show)
      return getStyleByPlatform(channel4Show.value.mapStyle, MAP_PLATFORM_MAPBOX)
    })

    watch(
      () => selectedMarker.marker,
      (selected, prevSelected) => {
        console.log("watch selectedMarker", selected, prevSelected)
        if (selected != prevSelected && prevSelected != null) {
          let className = prevSelected._element.className.replace(/yy-map-marker-active yy-map-marker-active-name/gi, "")

          prevSelected._element.childNodes[0].innerHTML = ""
          prevSelected._element.className = className

          prevSelected.setOffset([-12, 0])
        }
      }
    )

    watch(
      channelInfoList,
      (value) => {
        console.log('watch channelInfoList', value)
        console.log('watch channelInfoList markers', map.value._markers)
        markers.forEach(
          (marker) => {
            console.log('mapbox marker', marker)
            marker.remove()
          }
        )
        markers.splice(0, markers.length)
        selectedMarker.marker = null
        addMarkers()
      }
    )

    watch(channel4Show, (value) => {
      if (map.value) {
        map.value.setZoom(value.zoom)
        map.value.setCenter([value.centerLng, value.centerLat])
      }
    })

    watch(mapStyle, (value) => {
      if (map.value) {
        map.value.setStyle(value, { diff: true })
      }
    })

    return {
      map,
      markers,
      addMarkers,
      activeMarker,
      selectedMarker,
      mapStyle,
      channel4Show
    }
  },

  created () {
    this.$emitter.on("selectChannelItemEvent", this.selectChannelItem)
  },
  mounted () {
    this.initMap()
  },
  methods: {
    initMap () {
      this.map = new mapboxgl.Map({
        accessToken: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN,
        container: 'map',
        style: this.mapStyle,
        center: [this.channel4Show.centerLng, this.channel4Show.centerLat],
        zoom: this.channel4Show.zoom,
        antialias: false,
        attributionControl: false,
      })

      // 设置语言
      var language = new MapboxLanguage({ defaultLanguage: "zh-Hans" });
      this.map.addControl(language);

      this.map.on('load', () => {
        console.log('addMarkers map', this.map)
        console.log('addMarkers channelInfoList', this.channelInfoList)
        this.addMarkers()
      })
    },

    selectChannelItem (item) {
      console.log('select Channel Item:', item)
      let feature = item.feature
      let marker = this.map._markers.find((value) => {
        return value._element.id == feature.id
      })

      if (marker != undefined) {
        this.activeMarker(marker, item)
      }
      if (this.map.getZoom() < 6) {
        this.map.setCenter([feature.dataObject[0], feature.dataObject[1]])
      } else {
        this.map.flyTo({ center: [feature.dataObject[0], feature.dataObject[1]] })
      }
    },

  },
  beforeUnmounted () {
    this.$emitter.off("selectChannelItemEvent", this.selectChannelItem)
  }
};
</script>
<style lang="sass">
@import '@sass/map.sass'
#map
  .mapboxgl-ctrl-logo
    display: none
  .mapboxgl-canvas
    outline: none
</style>