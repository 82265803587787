<template>
  <q-select
    standout="bg-dark-color5 text-white"
    class="yy-map-selecter"
    v-model="mapSelected"
    :options="mapOptions"
    label="地图选择"
    @update:model-value="updateMapPlatform"
  />
</template>
<script>
import { ref } from "vue"
import useMapDataHandler from "@composables/useMapDataHandler.js"
export default {
  name: "MapSelector",
  emits: ["updateSelectedMap"],
  setup () {
    const { mapOptions, getSelectedMapOptions } = useMapDataHandler()
    const mapSelected = ref(getSelectedMapOptions())

    return {
      mapOptions,
      mapSelected
    }
  },
  methods: {
    updateMapPlatform (value) {
      console.log('updateMapPlatform', value)
      this.$store.dispatch("updateSelectedMap", value.platform)
      this.$emit("updateSelectedMap", value)
    }
  }
}
</script>

<style lang="sass">
@import "@sass/quasar.variables.sass"
.yy-map-selecter
  .q-field__control
    background-color: $dark-color5!important
    .q-field__native, .q-field__label, .q-field__marginal
      color: white!important
</style>
