<template>
  <div class="yy-search-wrapper row items-center">
    <input
      class="yy-search-box"
      v-model="searchKey"
      placeholder="频道内搜索"
      @blur="$emit('searchBlur')"
      autofocus
    />
    <div class="yy-search-icon-wrappr">
      <q-icon
        class="yy-search-icon"
        :name="'img:' + iconBase64.get('channelSearchLight')"
      />
    </div>
  </div>
</template>
<script>
import useDrawerMaterial from "@composables/useDrawerMaterial";
export default {
  name: "SearchBox0.6",
  emits: ["searchBlur"],
  setup () {
    const { iconBase64 } = useDrawerMaterial();
    return {
      iconBase64,
    }
  },
  methods: {

  }
}
</script>
<style lang="sass" scoped>
@import "@sass/quasar.sass"
.yy-search-wrapper
  margin-left: 8px
  background-color: $dark-color6
  border-radius: 16px
  border: 1px solid $main-color3
  font-size: 14px
  overflow: hidden
  .yy-search-icon-wrappr
    height: 32px
    width: 48px
    background-color: $main-color3
    text-align: center
    border: 1px solid $main-color3
    .yy-search-icon
      width: 17px
      height: 20px
      padding-top: 4px
      cursor: pointer
  .yy-search-box
    height: 32px
    width: 192px
    margin-left: 16px
    background-color: $dark-color6
    border: none
    outline: medium
    color: $gray-color5
    flex: auto
    // &:-webkit-input-placeholder,
    // &:-moz-placeholder,
    // &:-ms-input-placeholder
    @include input-placeholder
      color: $gray-color3
</style>