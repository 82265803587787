<template>
  <div class="yy-oper-panel column items-center">
    <q-btn flat class="yy-oper-btn" @click="$emit('addMapInfo', 'point')">
      <q-icon size="2.25rem" :name="'img:' + getIconData('mapPointBtn')" />
    </q-btn>
    <q-btn flat class="yy-oper-btn" @click="$emit('addMapInfo', 'line')">
      <q-icon size="2.25rem" :name="'img:' + getIconData('mapLineBtn')" />
    </q-btn>
    <q-btn flat class="yy-oper-btn" @click="$emit('addMapInfo', 'area')">
      <q-icon size="2.255rem" :name="'img:' + getIconData('mapAreaBtn')" />
    </q-btn>
  </div>
</template>
<script>
import useDrawerMaterial from "@composables/useDrawerMaterial";
export default {
  name: "OperationPanel",
  emits: ["addMapInfo"],
  setup () {
    const { getIconData } = useDrawerMaterial();
    return {
      getIconData,
    }
  },
}
</script>
<style lang="sass" scoped>
@import "@sass/quasar.variables.sass"
.yy-oper-panel
  .yy-oper-btn
    padding: 0!important
    width: 36px
    height: 36px
    margin-top: 4px
</style>