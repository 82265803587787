<template>
  <div class="yy-map-box" @click="searchBoxVisible = false">
    <component
      :is="mapPlatform.value"
      :channel="datasetMap"
      :channelInfoList="dataset"
    ></component>
    <div
      v-if="datasetName != ''"
      class="yy-name-panel-wrapper row items-center"
    >
      <q-icon
        class="yy-name-panel-icon"
        :name="'img:' + iconBase64.get('dataSetLight')"
      />
      <label>{{ datasetName }}</label>
    </div>

    <div class="yy-map-select-wrapper">
      <MapSelector @updateSelectedMap="updateMapPlatform"></MapSelector>
    </div>
  </div>
</template>
<script>

import MapBox from "@components/mapbox/MapBox";
import MapGaode from "@components/mapbox/MapGaode"
import MapSelector from "@components/mapbox/MapSelector"
import { ref, reactive } from "vue"
import useDrawerMaterial from "@composables/useDrawerMaterial";
import useDatasetOperations from '@/composables/useDatasetOperations'
import useMapDataHandler from "@composables/useMapDataHandler.js"
import { fetchDatasetByTableName } from "@api/dataset-api"
import { useStore } from "vuex";
export default {
  name: "Map4Dataset",
  setup () {
    const store = useStore();
    const { getDatasetByTableName } = useDatasetOperations()
    let { getSelectedMapOptions } = useMapDataHandler()
    const mapPlatform = ref(getSelectedMapOptions())
    const { iconBase64 } = useDrawerMaterial();
    const datasetMap = reactive({
      centerLng: 113.631419,
      centerLat: 34.753439,
      zoom: 4,
      owner: { userName: store.state.userName, nickName: store.state.user.nickname, avatar: store.state.userAvatar }
    })

    return {
      getDatasetByTableName,
      mapPlatform,
      iconBase64,
      datasetMap
    }
  },
  data: () => ({
    dataset: [],
    datasetName: "",

  }),
  components: {
    MapBox,
    MapGaode,
    MapSelector
  },
  created () {
    this.$emitter.on("selectDatasetItemEvent", this.selectDatasetItem)
  },

  methods: {
    async selectDatasetItem (item) {
      console.log('selectd dataset item', item)
      if (item) {
        this.datasetName = item.datasetName
        const result = await fetchDatasetByTableName(item.tableName)
        /*  this.dataset = result.map(item => {
           return {
             id: item.id,
             name: item.dataName,
             centerLng: item.dataObject.length > 0 ? item.dataObject[0] : null,
             centerLat: item.dataObject.length > 1 ? item.dataObject[1] : null
           }
         }) */
        this.dataset = [result]
      } else {
        this.datasetName = ''
        this.dataset = []
      }

      console.log('dataset for map', this.dataset)
    },
    updateMapPlatform (value) {
      console.log('update map platform', value)
      this.mapPlatform = value
    }
  },
  beforeUnmounted () {
    this.$emitter.off("selectDatasetItemEvent", this.selectDatasetItem)
  }
};

</script>
<style lang="sass" scoped>
@import "@sass/quasar.variables.sass"
.yy-map-box
  width: 100%
  height: 100vh
  position: relative
  background-color: $back-color2
  .yy-name-panel-wrapper
    z-index: 101
    position: absolute
    top: 16px
    left: 50%
    transform: translate(-50%)
    padding: 6px 12px
    background-color: $dark-color5
    border-radius: 24px
    min-width: 300px
    label
      margin-left: 12px
      color: $gray-color2
      font-size: 18px
      line-height: 36px
      font-weight: 400
    .yy-name-panel-icon
      width: 24px
      height: 24px
  .yy-info-panel-wrapper, .yy-add-panel-wrapper
    z-index: 101
    position: absolute
    right: 16px
    top: 16px
  .yy-info-panel-wrapper-expand
    height: 100vh
    right: 0
    top: 0
    background-color: $dark-color5
  .yy-oper-panel-wrapper
    z-index: 101
    position: absolute
    left: 6px
    bottom: 6px
    &-left
      left: $drawer-width + 6px
  .yy-map-select-wrapper
    z-index: 101
    position: absolute
    right: 16px
    bottom: 6px
  @media screen and (max-width: 900px)
    .yy-search-panel-wrapper
      z-index: 100
    .yy-info-panel-wrapper
      top: 86px
    .yy-info-panel-wrapper-expand
      top: 0
      padding-top: 86px
    .yy-add-panel-wrapper
      top: 86px
</style>