<template>
  <div class="yy-add-panel">
    <div class="yy-img-wrapper">
      <q-btn flat class="yy-img-add-btn" @click="$emit('hideInfoPanel')">
        <q-icon size="1.5rem" :name="'img:' + getIconData('addPointImgBtn')" />
      </q-btn>
    </div>
    <div class="yy-my-input-wrapper column">
      <input id="markName" autofocus value="美国陆军装备 " />
      <label class="order-first" for="markName">标记名称</label>
    </div>
    <div class="yy-my-input-wrapper column">
      <textarea
        id="markInfo"
        ref="textareaRef"
        v-model="content"
        :rows="textareaRows"
      ></textarea>
      <label class="order-first" for="markInfo">标记信息</label>
    </div>
    <div class="yy-btn-wrapper row justify-end">
      <q-btn
        unelevated
        color="main-color3"
        text-color="main-color5"
        class="yy-q-btn"
        label="取消"
        @click="cancel"
      />
      <q-btn
        unelevated
        color="main-color3"
        text-color="main-color5"
        class="yy-q-btn"
        label="保存"
        @click="commit"
      />
    </div>
  </div>
</template>
<script>
import useDrawerMaterial from "@composables/useDrawerMaterial";
import { ref, watch, nextTick, onMounted } from 'vue'
export default {
  name: "AddPanel",
  emits: ["hideAddPanel"],
  setup () {
    const textareaRef = ref(null);
    const textareaRows = ref(1)
    const content = ref('军事单位')

    onMounted(() => {
      nextTick(adjustTextareaSize);
    });

    watch(() => content.value, () => {
      nextTick(adjustTextareaSize);
    })

    // 参考vant组件库中的field组件
    const adjustTextareaSize = () => {
      let textarea = textareaRef.value

      textarea.style.height = 'auto';
      const height = textarea.scrollHeight + 10;

      if (height) { // 改变textarea高度达到自适应
        textarea.style.height = height + "px";
      }
    }

    const { getIconData } = useDrawerMaterial();
    return {
      getIconData,
      textareaRef,
      textareaRows,
      content
    }
  },
  data () {
    return {

    }
  },
  methods: {
    cancel () {
      this.$emit('hideAddPanel')
    },
    commit () {
      this.$emit('hideAddPanel')
    }
  }
}
</script>
<style lang="sass" scoped>
@import "@sass/common.edit.sass"
$item-width: 228px
.yy-add-panel
  min-height: 464px
  border-radius: 16px
  background-color: $dark-color5
  padding: 14px
  .yy-img-wrapper
    width: $item-width
  .yy-my-input-wrapper
    input, textarea
      width: $item-width
  .yy-btn-wrapper
    margin: 18px 0 24px
</style>