import { MAP_PLATFORM_MAPBOX, MAP_PLATFORM_GAODE } from "@config/constant"
import { useStore } from "vuex";
export default function useMapDataHandler () {
  const convert2PointCollection = function (channelInfoList) {
    var geojson = {
      type: "FeatureCollection",
      features: [],
    };
    channelInfoList.forEach((value) => {
      let feature = {
        type: "Feature",
        properties: {
          name: value.name,
          year: value.year,
          hot: value.hot,
          recommended: value.recommended,
          time: value.updateTime || value.createTime,
          code: value.channelCode
        },
        geometry: {
          type: "Point",
          coordinates: [value.centerLng, value.centerLat]
        }
      }
      geojson.features.push(feature)
    })
    console.log("convert to point collection", geojson)
    return geojson
  }
  const initChannelData = function (mapPlatform) {
    let initData = {
      mapStyle: defaultMapStyle[mapPlatform],
      zoom: 3.7,
      centerLng: 116.397228,
      centerLat: 39.909604,
    }
    return { ...initData }
  }
  const defaultMapStyle = {
    mapbox: "mapbox://styles/yebin2004/ck9qih0c90zkd1ip80c07tosw",
    amap: "amap://styles/6314a00630f0ce4439d779a1b6eafe30",
  }
  const getStyleByPlatform = function (styleStr, mapPlatform) {
    let styleArr = styleStr ? styleStr.split(",") : []
    let style = styleArr.find((value) => {
      return value.includes(mapPlatform)
    })
    return style || defaultMapStyle[mapPlatform]
  }

  const mapOptions = [{ platform: MAP_PLATFORM_MAPBOX, label: "MapBox", value: "MapBox" }, { platform: MAP_PLATFORM_GAODE, label: "高德地图", value: "MapGaode" }]

  const getMapOptions = function (platform) {
    return mapOptions.find(value => value.platform == platform)
  }

  const getSelectedMapOptions = function () {
    const platform = useStore().getters.selectedMap
    return mapOptions.find(value => value.platform == platform) || mapOptions[0]
  }

  return {
    defaultMapStyle,
    convert2PointCollection,
    initChannelData,
    getStyleByPlatform,
    mapOptions,
    getMapOptions,
    getSelectedMapOptions
  }
}