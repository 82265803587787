<template>
  <div id="map"></div>
</template>
<script>
import AMapLoader from '@amap/amap-jsapi-loader';
import useMapDataHandler from '@/composables/useMapDataHandler'
import { ref, reactive, watch, computed, toRefs, getCurrentInstance } from "vue"
import { MAP_PLATFORM_GAODE } from '@config/constant'
import useMapGaodeOperations from "@composables/useMapGaodeOperations";
const { initChannelData, getStyleByPlatform } = useMapDataHandler()
export default {
  name: "MapGaode",
  props: {
    channel: {
      type: Object, default () {
        return initChannelData(MAP_PLATFORM_GAODE)
      }
    },
    channelInfoList: {
      type: Array, default () {
        return []
      }
    }
  },
  setup (props) {
    // 配置安全密钥
    if (process.env.NODE_ENV == "development") {
      window._AMapSecurityConfig = { securityJsCode: process.env.VUE_APP_AMAP_SECURITY };
    } else {
      window._AMapSecurityConfig = {
        serviceHost: process.env.VUE_APP_AMAP_SERVICE_BASE_URL,
        // 例如 ：serviceHost:'http://1.1.1.1:80/_AMapService',
      }
    }

    console.log('window._AMapSecurityConfig', process.env.VUE_APP_AMAP_SERVICE_BASE_URL)

    const emitter = getCurrentInstance().appContext.config.globalProperties.$emitter
    console.log("map gaode setup emitter", emitter)

    const map = ref(null)
    const AMap = ref(null)
    const markers = reactive([])
    const selectedMarker = ref(null)
    const { channel, channelInfoList } = toRefs(props)

    const { addMarkers, activeMarker } = useMapGaodeOperations(map, channel, channelInfoList, selectedMarker, emitter, markers, AMap)

    const channel4Show = computed(() => {
      if (channel.value == null) {
        return initChannelData(MAP_PLATFORM_GAODE)
      } else {
        return channel.value
      }
    })

    const mapStyle = computed(() => getStyleByPlatform(channel4Show.value.mapStyle, MAP_PLATFORM_GAODE))

    watch(
      selectedMarker,
      (selected, prevSelected) => {
        console.log("watch selectedMarker", selected, prevSelected)
        if (selected != prevSelected && prevSelected != null) {
          let rootEl = prevSelected.dom
          let el = prevSelected.dom.childNodes[0]

          rootEl.className = rootEl.className.replace(/ yy-gaode-marker-active/gi, "")

          let className = el.className.replace(/yy-map-marker-active yy-map-marker-active-name/gi, "")
          el.childNodes[0].innerHTML = ""
          el.className = className
          prevSelected.setOffset([-12, 0])
        }
      }
    )

    watch(
      channelInfoList,
      (value) => {
        console.log('map gaode watch channelInfoList', value)
        map.value.remove(markers)
        markers.splice(0, markers.length)
        selectedMarker.value = null
        addMarkers()
      }
    )

    watch(channel4Show, (value) => {
      if (map.value) {
        map.value.setZoom(value.zoom)
        map.value.setCenter([value.centerLng, value.centerLat], true)
      }
    })

    watch(mapStyle, (value) => {
      if (map.value) {
        map.value.setMapStyle(value)
      }
    })

    return {
      map,
      AMap,
      markers,
      selectedMarker,
      mapStyle,
      addMarkers,
      activeMarker,
      channel4Show
    }
  },

  created () {
    this.$emitter.on("selectChannelItemEvent", this.selectChannelItem)
  },
  mounted () {
    //DOM初始化完成进行地图初始化
    this.ininMap();
  },
  methods: {
    ininMap () {
      AMapLoader.load({
        "key": process.env.VUE_APP_AMAP_KEY,   // 申请好的Web端开发者Key，首次调用 load 时必填
        "version": "2.0",       // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        "plugins": [],           // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        "AMapUI": {              // 是否加载 AMapUI，缺省不加载
          "version": '1.1',    // AMapUI 缺省 1.1
          "plugins": [],        // 需要加载的 AMapUI ui插件
        },
        "Loca": {                 // 是否加载 Loca， 缺省不加载
          "version": '2.0'   // Loca 版本，缺省 1.3.2
        },
      }).then((AMap) => {
        console.log('this channel:', this.channel4Show)
        this.AMap = AMap
        this.map = new AMap.Map('map', {
          mapStyle: this.mapStyle,
          zoom: this.channel4Show.zoom,              //级别
          center: [this.channel4Show.centerLng, this.channel4Show.centerLat],   //中心点坐标
          viewMode: '3D'                     //使用3D视图
        });
        console.log('this.map', this.map)
        this.addMarkers()

      }).catch(e => {
        console.log(e);
      })
    },
    /* addMarker () {
      for (let item of this.channelInfoList) {
        var content = `<div class="yy-map-marker-default" name="${item.name}" id="${item.id}"><div></div></div>`;
        let marker = new this.AMap.Marker({
          content,
          position: [item.centerLng, item.centerLat],
          anchor: "bottom-left",
          offset: [-12, 0]
        });

        marker.on('click', (event) => {
          console.log('marker click', marker, event)
          this.activeMarker(event.target, item, event)
          //event.target.setOffset(new AMap.Size(-22, -7));
        })
        this.map.add(marker);
        this.markers.push(marker)
      }
    },
    activeMarker (marker, item, e) {
      console.log('activeMarker:', marker, item, e)
      let sm = this.selectedMarker
      let el = marker.dom.childNodes[0]
      if (sm != null && sm.dom.childNodes[0].id == el.id) {
        if (e) this.$emitter.emit('clickActiveMarkerEvent')
        return
      }
      this.$emitter.emit('activeMarkerEvent', item)
      marker.dom.className = marker.dom.className + " yy-gaode-marker-active"
      el.className = el.className + " yy-map-marker-active yy-map-marker-active-name"
      el.childNodes[0].innerHTML = item.name
      console.log('activeMarker el:', el)
      marker.setOffset([-22, -7]);
      this.selectedMarker = marker
    }, */
    selectChannelItem (item) {
      console.log('select Channel Item:', item, this.markers)
      let feature = item.feature
      let marker = this.markers.find((value) => {
        return value.dom.childNodes[0].id == feature.id
      })

      if (marker != undefined) {
        this.activeMarker(marker, item)
      }
      this.map.setCenter([feature.dataObject[0], feature.dataObject[1]], true)
    },
  },
  beforeUnmounted () {
    this.$emitter.off("selectChannelItemEvent", this.selectChannelItem)
  }
}

</script>
<style lang="sass">
@import '@sass/map.sass'
.amap-logo
  display: none!important
.amap-copyright
  opacity: 0!important
.yy-gaode-marker-active
  z-index: 100!important
  transform-origin: 22px 51px!important
  transform: translate(-22px, -51px)!important
</style>