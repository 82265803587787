<template>
  <div class="yy-map-box" @click="searchBoxVisible = false">
    <component
      :is="mapPlatform.value"
      :channel="selectedChannel"
      :channelInfoList="channelInfoList"
    ></component>

    <div
      v-if="selectedChannel != null"
      class="yy-search-panel-wrapper row justify-center"
    >
      <search-panel
        :channelName="selectedChannel.channelName"
        :channelAvatar="selectedChannel.avatar"
        v-model:searchBoxVisible="searchBoxVisible"
      ></search-panel>
    </div>
    <div
      v-show="infoPanelVisible"
      class="yy-info-panel-wrapper"
      :class="{ 'yy-info-panel-wrapper-expand': infoPanelExpanded }"
    >
      <InfoPanel
        @hideInfoPanel="infoPanelVisible = false"
        v-model:expanded="infoPanelExpanded"
        :editable="editable"
      ></InfoPanel>
    </div>
    <div
      v-if="editable"
      class="yy-oper-panel-wrapper"
      :class="{ 'yy-oper-panel-wrapper-left': drawExpanded }"
    >
      <OperPanel @addMapInfo="addMapInfo"></OperPanel>
    </div>
    <div
      v-if="editable"
      v-show="addPanelVisible"
      class="yy-add-panel-wrapper"
      :class="leftClass"
    >
      <AddPanel @hideAddPanel="addPanelVisible = false"></AddPanel>
    </div>
    <div class="yy-map-select-wrapper">
      <MapSelector @updateSelectedMap="updateMapPlatform"></MapSelector>
    </div>
  </div>
</template>
<script>
import SearchPanel from '@/components/mapbox/SearchPanel'
import InfoPanel from '@/components/mapbox/InfoPanel'
import OperPanel from '@/components/mapbox/OperPanel'
import AddPanel from '@/components/mapbox/AddPanel'
import MapBox from "@components/mapbox/MapBox";
import MapGaode from "@components/mapbox/MapGaode"
import MapSelector from "@components/mapbox/MapSelector"
//import useUserPermission from "@composables/useUserPermission";
import useMapDataHandler from "@composables/useMapDataHandler.js"
import { getChannelData } from "@api/channel-api"
import { useStore } from "vuex";
import { ref, /* computed, */ watch } from "vue"
export default {
  name: "Map4Channel",
  setup () {
    const store = useStore();
    //const selectedChannel = computed(() => store.getters.selectedChannel)
    //const channelInfoList = computed(() => store.getters.channelInfoList)
    const selectedChannel = ref(store.getters.selectedChannel)
    const channelInfoList = ref(store.getters.channelInfoList)
    //const { isEditable } = useUserPermission()

    /* let editable = computed(() => {
      if (selectedChannel.value == null) return false
      return isEditable(selectedChannel.value.channelCode)
    }); */
    let { getSelectedMapOptions } = useMapDataHandler()
    const mapPlatform = ref(getSelectedMapOptions())
    const editable = ref(false)

    watch(() => store.getters.selectedChannel, (newValue) => {
      if (!editable.value) selectedChannel.value = newValue
    })

    watch(() => store.getters.channelInfoList, (newValue) => {
      if (!editable.value) channelInfoList.value = newValue
    })

    return {
      selectedChannel,
      channelInfoList,
      editable,
      mapPlatform
    }
  },
  data: () => ({
    infoPanelVisible: false,
    infoPanelExpanded: false,
    addPanelVisible: false,
    drawExpanded: true,
    searchBoxVisible: false
  }),
  components: {
    SearchPanel,
    InfoPanel,
    OperPanel,
    AddPanel,
    MapBox,
    MapGaode,
    MapSelector
  },
  created () {
    this.$emitter.on("selectChannelEvent", this.showChannel)
    this.$emitter.on("selectChannelItemEvent", this.selectChannelItem)
    this.$emitter.on("toggleDrawerEvent", this.toggleDrawer)
    this.$emitter.on("clickActiveMarkerEvent", this.clickActiveMarker)
    this.$emitter.on("showSharedChannelEvent", this.showSharedChannel)
    console.log('map4channel created emitter', this.$emitter)
  },

  methods: {
    async showChannel (channel) {
      console.log("show my channel on map", channel)

      try {
        this.editable = true
        this.addPanelVisible = false
        if (channel) {
          this.channelInfoList = await getChannelData({ id: channel.id })
        } else {
          this.channelInfoList = null
        }
      } catch (err) {
        console.log('fetch channel data fail', err)
        this.channelInfoList = null
      }
      console.log('my channel data', this.channelInfoList)
      this.selectedChannel = channel
    },

    async selectChannelItem () {
      this.addPanelVisible = false
    },

    addMapInfo (type) {
      this.infoPanelVisible = false
      this.addPanelVisible = true
      console.log('add map info：', type)
    },
    toggleDrawer (e) {
      console.log('toggle Drawer', e)
      this.drawExpanded = e == 0 ? false : true


    },
    clickActiveMarker () {
      console.log('clickActiveMarker by emitter')
      this.infoPanelVisible = true
      this.addPanelVisible = false
    },
    updateMapPlatform (value) {
      this.infoPanelVisible = false
      this.addPanelVisible = false
      this.mapPlatform = value
    },
    showSharedChannel () {
      if (this.editable) {
        this.editable = false
        this.selectedChannel = this.$store.getters.selectedChannel
        this.channelInfoList = this.$store.getters.channelInfoList
      }
    }
  },
  beforeUnmounted () {
    this.$emitter.off("selectChannelEvent", this.showChannel)
    this.$emitter.off("selectChannelItemEvent", this.selectChannelItem)
    this.$emitter.off("toggleDrawerEvent", this.toggleDrawer)
    this.$emitter.off("clickActiveMarkerEvent", this.clickActiveMarker)
    this.$emitter.off("showSharedChannelEvent", this.showSharedChannel)
  }
};

</script>
<style lang="sass" scoped>
@import "@sass/quasar.variables.sass"
.yy-map-box
  width: 100%
  height: 100vh
  position: relative
  background-color: $back-color2
  .yy-search-panel-wrapper
    z-index: 101
    position: absolute
    top: 16px
    left: 50%
    transform: translate(-50%)
  .yy-info-panel-wrapper, .yy-add-panel-wrapper
    z-index: 101
    position: absolute
    right: 16px
    top: 16px
  .yy-info-panel-wrapper-expand
    height: 100vh
    right: 0
    top: 0
    background-color: $dark-color5
  .yy-oper-panel-wrapper
    z-index: 101
    position: absolute
    left: 6px
    bottom: 6px
    &-left
      left: $drawer-width + 6px
  .yy-map-select-wrapper
    z-index: 101
    position: absolute
    right: 16px
    bottom: 6px
  @media screen and (max-width: 900px)
    .yy-search-panel-wrapper
      z-index: 100
    .yy-info-panel-wrapper
      top: 86px
    .yy-info-panel-wrapper-expand
      top: 0
      padding-top: 86px
    .yy-add-panel-wrapper
      top: 86px
</style>