<template>
  <q-page class="flex justify-center" :class="{ 'yy-home': !mapVisible }">
    <template v-if="!mapVisible">
      <h1 class="yy-slogan">标记世界 点亮生活</h1>
      <Copyright></Copyright>
    </template>
    <MapContainer v-else></MapContainer>
  </q-page>
</template>

<script>
import MapContainer from "@components/mapbox/MapContainer";
import Copyright from "@components/Copyright"
import { useStore } from "vuex";
import { computed } from "vue"
export default {
  name: "Home",
  setup () {
    const selectedChannel = useStore().getters.selectedChannel

    const mapVisible = computed(() => {
      console.log('selected channel', selectedChannel)
      /* if (selectedChannel == null) return false
      else return true */
      return true
    })

    return {
      mapVisible
    }
  },

  components: {
    MapContainer,
    Copyright,
  },
  created () {

  }
};
</script>

<style lang="sass" scoped>
@import "@sass/common.sass"
@import "@sass/quasar.variables.sass"
.yy-home
  background: $back-color2 center top/cover no-repeat url("~@img/bg-home.png")

.yy-slogan
  @include center-text

.yy-copyright
  position: fixed
  bottom: 0
  margin: 0 auto 4px
  color: #fff
  font-size: 12px
  a
    color: #fff
    text-decoration: none
  .yy-icon-gongan
    margin-left: 14px
    img
      float: left
      width: 18px
      height: 18px
    .yy-num-gongan
      float: left
      height: 20px
      line-height: 20px
      margin: 0px 0px 0px 5px
      color: #939393
</style>
