<template>
  <div
    class="row no-wrap items-center yy-map-search-panel"
    @click.stop="channelNameVisible = false"
  >
    <div>
      <q-icon
        class="yy-panel-icon"
        :name="
          'img:' +
          (channelAvatar || require('../../assets/img/channel-avatar.png'))
        "
      />
      <q-tooltip
        v-if="!channelNameVisible"
        class="yy-avatar-tip"
        anchor="center right"
        self="center left"
        :offset="[0, 0]"
        transition-show="scale"
        transition-hide="scale"
        >{{ name4Show }}</q-tooltip
      >
    </div>
    <transition name="slide-fade-aside" mode="out-in">
      <div class="row no-wrap items-center" v-if="channelNameVisible">
        <div class="yy-channel-name">{{ name4Show }}</div>
        <q-icon
          class="yy-search-icon"
          @click="channelNameVisible = !channelNameVisible"
          :name="'img:' + iconBase64.get('channelSearch')"
        />
      </div>
      <SearchBox v-else></SearchBox>
    </transition>
  </div>
</template>
<script>
import { computed } from "vue"
import useDrawerMaterial from "@composables/useDrawerMaterial";
import SearchBox from "@components/SearchBox0.6"
export default {
  name: "SearchPanel",
  emits: ['update:searchBoxVisible'],
  setup (props, { emit }) {
    const { iconBase64 } = useDrawerMaterial();
    const channelNameVisible = computed({
      get: () => !props.searchBoxVisible,
      set: (val) => {
        emit('update:searchBoxVisible', !val);
      }
    });

    const name4Show = computed(() => props.channelName || "频道名称")

    /* onMounted(() => {
      window.onresize = () => {
        return (() => {
          if (document.body.clientWidth > 600) {
            channelNameVisible.value = true
          } else {
            channelNameVisible.value = false
          }
        })()
      }
    }); */
    return {
      iconBase64,
      channelNameVisible,
      name4Show
    }
  },
  props: {
    channelName: { type: String },
    channelAvatar: { type: String },
    searchBoxVisible: {
      type: Boolean,
      default: false
    }
  },
  components: {
    SearchBox
  }
}
</script>
<style lang="sass" scoped>
@import @sass/transition.sass
@import "@sass/quasar.variables.sass"
$name-max-width: calc(600px - 6px - 36px - 6px - 12px - 32px - 6px)
.yy-map-search-panel
  padding: 6px 6px
  background-color: $dark-color5
  border-radius: 24px
  .yy-panel-icon
    width: 32px
    height: 32px
    border-radius: 50%
    border: 2px solid $gray-color5
    object-fit: cover
  .yy-channel-name
    max-width: $name-max-width
    margin: 0 36px
    font-size: 18px
    line-height: 36px
    color: $gray-color2
    word-break: keep-all
  .yy-search-icon
    width: 17px
    height: 20px
    padding-top: 4px
    margin-right: 12px
    cursor: pointer

  .animate__animated.animate__fadeIn
    --animate-duration: 0.25s
</style>