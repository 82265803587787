<template>
  <div v-show="show" class="yy-info-panel">
    <div class="yy-top-btns row items-center justify-end">
      <q-btn flat round class="yy-top-btn">
        <q-icon
          size="0.875rem"
          :name="'img:' + sizeIconData"
          @click="$emit('update:expanded', !expanded)"
        />
      </q-btn>
      <q-btn flat round class="yy-top-btn" @click="$emit('hideInfoPanel')">
        <q-icon size="0.875rem" :name="'img:' + getIconData('close')" />
      </q-btn>
    </div>
    <q-img
      v-if="feature != null"
      :src="featurePicture"
      spinner-color="white"
      class="yy-info-img"
      alt="相关图片"
    >
      <div
        v-if="featurePicture == null"
        class="absolute-full text-main-color5 flex flex-center"
      >
        暂无图片
      </div>
      <template v-if="featurePicture != null" v-slot:error>
        <div class="absolute-full flex flex-center bg-gray-color5 text-white">
          无法加载图片
        </div>
      </template>
    </q-img>
    <div v-if="feature != null" class="yy-info-box">
      <div class="yy-info-title">{{ feature.dataName }}</div>
      <div class="yy-info-addr">
        {{ feature.addr || '暂无地址' }}
      </div>
      <div class="yy-info-meta row items-center">
        <q-avatar size="1.5rem">
          <img :src="owner.avatar" />
          <q-tooltip
            class="yy-avatar-tip"
            anchor="bottom middle"
            self="top middle"
            :offset="[0, 0]"
            transition-show="slide-up"
            transition-hide="slide-down"
            >{{ owner.nickName || owner.userName }}</q-tooltip
          >
        </q-avatar>
        <q-btn v-if="editable" flat round class="yy-info-meta-btn">
          <q-icon size="1.5rem" :name="'img:' + getIconData('edit')" />
        </q-btn>
        <q-btn flat round class="yy-info-meta-btn">
          <q-icon size="1.5rem" :name="'img:' + getIconData('favoriteBtn')" />
        </q-btn>
        <q-btn flat round class="yy-info-meta-btn">
          <q-icon size="1.5rem" :name="'img:' + getIconData('linkBtn')" />
        </q-btn>
        <div class="yy-info-meta-count">{{ feature.views }} 次浏览</div>
      </div>
    </div>
  </div>
</template>
<script>
import useDrawerMaterial from "@composables/useDrawerMaterial";
import { ref, computed } from 'vue';
export default {
  name: "InfoPanel",
  emits: ["hideInfoPanel", 'update:expanded'],
  props: {
    url: { type: String, default: "/img/temp-info-img.png" },
    title: { type: String, default: "美国陆军装备司令部" },
    addr: { type: String, default: "位于美国佛罗里达州多拉军事单位" },
    avatar: { type: String, default: "/img/temp-zcy.png" },
    count: { type: String, default: "16,584 次浏览" },
    expanded: { type: Boolean, default: false },
    editable: { type: Boolean, default: false }
  },
  setup (props) {
    const feature = ref(null)
    const { getIconData } = useDrawerMaterial();
    const sizeIconData = computed({
      get: () => {
        if (!props.expanded) return getIconData('expandBtn')
        else return getIconData('shrinkBtn')
      }
    })

    const featurePicture = computed(() => {
      let picture = feature.value == null ? null : feature.value.picture
      if (picture != null && picture != "") {
        picture = process.env.VUE_APP_API_BASE_STATIC_URL + picture
      } else {
        picture = null
      }
      return picture
    })

    return {
      getIconData,
      sizeIconData,
      featurePicture,
      feature
    }
  },
  data () {
    return {
      show: false,
      owner: null
    }
  },
  created () {
    this.$emitter.on("selectChannelItemEvent", this.updateChannelInfo)
    this.$emitter.on("activeMarkerEvent", this.updateChannelInfo)
  },
  methods: {
    updateChannelInfo (item) {
      console.log('update channel info:', item)
      this.feature = item.feature
      this.owner = item.owner
      this.show = true
    }
  },
  beforeUnmounted () {
    this.$emitter.off("selectChannelItemEvent", this.updateChannelInfo)
    this.$emitter.off("activeMarkerEvent", this.updateChannelInfo)
  }
}
</script>
<style lang="sass" scoped>
@import "@sass/quasar.variables.sass"
@mixin word-font
  font-size: 12px
  font-weight: 400
  color: $gray-color5
.yy-info-panel
  min-height: 464px
  border-radius: 16px
  background-color: $dark-color5
  .yy-top-btns
    padding: 7px 7px
    .yy-top-btn
      min-width: 14px
      min-height: 14px
      width: 16px
      height: 16px
      margin-right: 10px
  .yy-info-img
    width: 256px
    height: 192px
    background-color: $gray-color5
  .yy-info-box
    padding: 32px 14px 7px
    .yy-info-title
      width: 228px
      font-size: 24px
      font-weight: 700
      line-height: 28px
      color: $gray-color2
      padding: 10px 0
    .yy-info-addr
      @include word-font
      width: 228px
      line-height: 14px
      padding: 5px 0
    .yy-info-meta
      &-btn
        min-width: 24px
        min-height: 24px
        width: 24px
        height: 24px
        margin-left: 4px
      &-count
        margin-left: 10px
        @include word-font
</style>