import mapboxgl from 'mapbox-gl'

export default function useMapBoxObjects (map, channel, channelInfoList, selectedMarker, emitter, markers) {
  // extend mapboxGL Marker so we can pass in an onClick handler
  class ClickableMarker extends mapboxgl.Marker {
    // new method onClick, sets _handleClick to a function you pass in
    onClick (handleClick) {
      this._handleClick = handleClick;
      return this;
    }

    // the existing _onMapClick was there to trigger a popup
    // but we are hijacking it to run a function we define
    _onMapClick (e) {
      const targetElement = e.originalEvent.target;
      const element = this._element;

      if (this._handleClick && (targetElement === element || element.contains((targetElement)))) {
        this._handleClick(e, this);
      }
    }
  };
  const createClickableMarker = function (el) {
    return new ClickableMarker(el)
  }

  const addMarkers = function () {
    if (!channelInfoList.value) return
    // add markers to map
    console.log('addMarkers map', map)
    console.log('addMarkers channelInfoList', channelInfoList)
    for (const sub of channelInfoList.value) {
      for (const item of sub) {
        // create a HTML element for each feature
        const el = document.createElement('div');
        el.innerHTML = "<div></div>"
        el.className = 'yy-map-marker-default';
        el.name = item.dataName
        el.id = item.id

        let marker = createClickableMarker({ element: el, anchor: "bottom-left", offset: [-12, 0] })

        marker.setLngLat([item.dataObject[0], item.dataObject[1]])
          .onClick((e, marker) => {
            console.log('marker click event:', marker)
            activeMarker(marker, { feature: item, owner: channel.value.owner }, e)
          })
          .addTo(map.value);
        markers.push(marker)
      }
    }
  }

  const activeMarker = function (marker, item, e) {

    console.log("activeMarker emitter", emitter)
    let m = selectedMarker.marker
    if (m != null && m._element.id == marker._element.id) {
      if (e) emitter.emit('clickActiveMarkerEvent')
      return
    }
    emitter.emit('activeMarkerEvent', item)
    let feature = item.feature
    marker._element.className = marker._element.className + " yy-map-marker-active yy-map-marker-active-name"
    marker._element.childNodes[0].innerHTML = feature.dataName
    marker.setOffset([-22, -7]);
    selectedMarker.marker = marker
    selectedMarker.feature = feature

    console.log('this.selectedMarker.feature ', selectedMarker.feature)
  }

  return {
    addMarkers,
    activeMarker
  }
}