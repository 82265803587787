<template>
  <component :is="comName"></component>
</template>
<script>
import Map4Channel from '@/components/mapbox/Map4Channel'
import Map4Dataset from '@/components/mapbox/Map4Dataset'
export default {
  name: "MapContainer",
  data () {
    return {
      comName: "Map4Channel"
    }
  },
  components: {
    Map4Channel,
    Map4Dataset
  },
  created () {
    this.$emitter.on("changeMapComEvent", this.changeMapCom)
  },
  methods: {
    changeMapCom (comName) {
      console.log('change map com', comName)
      this.comName = comName
    }
  }
};

</script>