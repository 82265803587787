export default function useMapGaodeOperations (map, channel, channelInfoList, selectedMarker, emitter, markers, AMap) {

  const addMarkers = function () {
    if (!channelInfoList.value) return
    // add markers to map
    console.log('addMarkers map', map)
    console.log('addMarkers channelInfoList', channelInfoList)
    for (let sub of channelInfoList.value) {
      for (let item of sub) {
        var content = `<div class="yy-map-marker-default" name="${item.dataName}" id="${item.id}"><div></div></div>`;
        let marker = new AMap.value.Marker({
          content,
          position: [item.dataObject[0], item.dataObject[1]],
          anchor: "bottom-left",
          offset: [-12, 0]
        });

        marker.on('click', (event) => {
          console.log('marker click', marker, event)
          activeMarker(event.target, { feature: item, owner: channel.value.owner }, event)
          //event.target.setOffset(new AMap.Size(-22, -7));
        })
        map.value.add(marker);
        markers.push(marker)
      }
    }
  }

  const activeMarker = function (marker, item, e) {
    console.log('map gaode activeMarker:', marker, item, e)
    let sm = selectedMarker.value
    let el = marker.dom.childNodes[0]
    if (sm != null && sm.dom.childNodes[0].id == el.id) {
      if (e) emitter.emit('clickActiveMarkerEvent')
      return
    }
    emitter.emit('activeMarkerEvent', item)
    marker.dom.className = marker.dom.className + " yy-gaode-marker-active"
    el.className = el.className + " yy-map-marker-active yy-map-marker-active-name"
    el.childNodes[0].innerHTML = item.feature.dataName
    console.log('activeMarker el:', el)
    marker.setOffset([-22, -7]);
    selectedMarker.value = marker
  }

  return {
    addMarkers,
    activeMarker
  }
}